import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FeatureGroup } from "react-leaflet";
import { useModal } from "shared";
import { useFieldApi } from "../hooks/fieldApi";
import { updateField } from "../redux/modules/farmiq";
import { selectFarm, selectFilteredFields } from "../redux/modules/farmiq/selectors";
import DrawMapPolygonTool from "./widgets/DrawMapPolygonTool";
import ResizableMap from "./widgets/ResizableMap";


export default () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const fieldApi = useFieldApi();
    const farm = useSelector(selectFarm);
    const fields = useSelector(selectFilteredFields);
    const [selectedField, setSelectedField] = useState(null);
    const [redrawFlag, setRedrawFlag] = useState(0);

    if (fields == null) return;

    function handleNewField(attributes) {
        if (attributes) {
            fieldApi.createField(attributes, farm.id, (field) => {
                dispatch(updateField(field));
                setRedrawFlag(redrawFlag + 1);
            });
        } else {
            setRedrawFlag(redrawFlag + 1);
        }
    }

    function handleFieldClick(fieldId) {
        openModal(
            t(!fieldId ? "title-new-crop-entry" : "title-update-crop-entry"),
            "components/widgets/FieldEditModal",
            null,
            { fieldId }
        );
    }

    const farmLocation = farm.location ? { lat: farm.location.coordinates[1], lng: farm.location.coordinates[0] } : null;

    return (<ResizableMap
        onFieldClick={handleFieldClick}
        fields={fields}
        defaultLocation={farmLocation}
        selectedField={selectedField}
    >
        <FeatureGroup key={redrawFlag}>
            <DrawMapPolygonTool onCreate={handleNewField} />
        </FeatureGroup>
    </ResizableMap>);
};
