import { produce } from "immer";

const initialState = {
    farms: null,
    selectedFarm: null,
    mapPosition: null,
    crops: null,
    mode: "iq",
};

const reducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_FARM_FIELDS":
            const farms = action.payload.farms;
            if (farms != null) {
                farms.forEach(f => {
                    if (!f.fields) f.fields = [];
                });
            }
            if (farms?.length) {
                const selectedFarmId = localStorage.getItem("agricircle_farm");
                if (selectedFarmId) {
                    const farm = farms.find(f => f.id === selectedFarmId);
                    if (farm)
                        draft.selectedFarm = farm;
                    else {
                        draft.selectedFarm = farms[0];
                        localStorage.setItem("agricircle_farm", farms[0].id);
                    }
                } else {
                    draft.selectedFarm = farms[0];
                    localStorage.setItem("agricircle_farm", farms[0].id);
                }
            } else {
                draft.selectedFarm = null;
                localStorage.setItem("agricircle_farm", null);
            }
            draft.farms = farms;
            break;
        case "UPDATE_FIELD":
            const updateFields = state.selectedFarm.fields.filter(field => field.id != action.payload.field.id);
            updateFields.push(action.payload.field);
            draft.selectedFarm = { ...state.selectedFarm, fields: updateFields };
            draft.farms = draft.farms.map(f => f.id == draft.selectedFarm.id ? draft.selectedFarm : f);
            break;
        case "DELETE_FIELD":
            const remainingFields = state.selectedFarm.fields.filter(field => field.id != action.payload.fieldId);
            draft.selectedFarm = { ...state.selectedFarm, fields: remainingFields };
            draft.farms = draft.farms.map(f => f.id == draft.selectedFarm.id ? draft.selectedFarm : f);
            break;
        case "SELECT_FARM":
            if (state.selectedFarm?.id === action.payload.farmId) return;
            if (action.payload.farmId != null) {
                const selectedFarm = state.farms.find(farm => farm.id == action.payload.farmId);
                draft.selectedFarm = selectedFarm;
                localStorage.setItem("agricircle_farm", action.payload.farmId);
            } else {
                draft.selectedFarm = null;
            }
            break;
        case "SET_MAP_POSITION":
            draft.mapPosition = action.payload.position;
            break;
        case "SET_CROPS":
            const cropsMap = {};
            if (action.payload.crops?.length)
                action.payload.crops.forEach(c => cropsMap[c.id] = c);
            draft.crops = { list: action.payload.crops, map: cropsMap };
            break;
        case "SET_MODE":
            draft.mode = action.payload.mode;
            break;
    }
});

export default reducer;

export const setFarmFields = (farms) => ({ type: "SET_FARM_FIELDS", payload: { farms } });

export const updateField = (field) => ({ type: "UPDATE_FIELD", payload: { field } });

export const deleteField = (fieldId) => ({ type: "DELETE_FIELD", payload: { fieldId } });

export const setSelectedFarm = (farmId) => ({ type: "SELECT_FARM", payload: { farmId } });

export const setMapPosition = (position) => ({ type: "SET_MAP_POSITION", payload: { position } });

export const setCrops = (crops) => ({ type: "SET_CROPS", payload: { crops } });

export const setMode = (mode) => ({ type: "SET_MODE", payload: { mode } });

