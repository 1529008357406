import { useSelector } from "react-redux";
import { selectCrops, selectCropsMap } from "../redux/modules/farmiq/selectors";

export const useCropUtils = () => {
    const crops = useSelector(selectCrops);
    const cropsMap = useSelector(selectCropsMap);

    function cropName(cropId) {
        if (!cropId) return null;
        return cropsMap?.[cropId]?.name || cropId;
    }

    return { crops, cropName };
};
