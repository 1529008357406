import { useSelector } from "react-redux";
import { selectFarms, selectFields, selectMode } from "../redux/modules/farmiq/selectors";
import FarmAssessment from "./FarmAssessment";
import FarmEdit from "./FarmEdit";
import NewRegistration from "./NewRegistration";


export default () => {
    const mode = useSelector(selectMode);
    const farms = useSelector(selectFarms);
    const fields = useSelector(selectFields);

    if (farms == null) return null;

    if (farms.length == 0)
        return <NewRegistration />;

    if (fields == null) return null;

    return mode == "iq" ? <FarmAssessment /> : <FarmEdit />;
};
