import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

const InnerControl = L.Control.extend({
    options: {
        classnames: "", // Default additional class names
    },
    onAdd: function (map) {
        const anchor = L.DomUtil.create("a", this.options.classnames || "");
        anchor.href = "#";
        anchor.title = "Re-center Map";
        anchor.style.fontSize = "16px";
        const outer2 = L.DomUtil.create("div", "leaflet-bar");
        outer2.append(anchor);
        const outer = L.DomUtil.create("div", "leaflet-control");
        outer.appendChild(outer2);
        L.DomEvent.on(outer, "click", this.onClick, this);
        return outer;
    },
    onClick: function (event) {
        // Handle the click event within the control
        if (this.options.onClick) {
            this.options.onClick(event);
        }
    }
});

export default ({ position, classnames, onClick }) => {
    const map = useMap();

    useEffect(() => {
        // Ensure map is available before creating the control
        if (map) {
            // Add the custom control to the map
            const control = new InnerControl({ position, onClick, classnames });
            control.addTo(map);
        }
    }, [map]);

    return null; // CustomControlWrapper doesn"t render anything
};
