import { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MapContainer, LayersControl, GeoJSON, FeatureGroup, Popup, useMap } from "react-leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { GOOGLE_API_KEY } from "shared";
import { selectMapLayer } from "../../redux/modules/persist/selectors";
import { selectMapPosition } from "../../redux/modules/farmiq/selectors";
import { setMapLayer } from "../../redux/modules/persist";
import { setMapPosition } from "../../redux/modules/farmiq";
import CustomLeafletControl from "./CustomLeafletControl";

const MyCenter = ({ onChange }) => {
    const map = useMap();

    useEffect(() => {
        if (map) {
            const updateMapState = () => onChange && onChange({ center: map.getCenter(), zoom: map.getZoom() });

            map.on("move", updateMapState);
            updateMapState(); // Initial update

            return () => {
                map.off("move", updateMapState); // Cleanup event listener
            };
        }
    }, [map]);

    return null;
};

const updateBounds = (field, currentBounds) => {
    const bounds = currentBounds || [[null, null], [null, null]];
    field.boundaries.coordinates[0].forEach(c => {
        if (bounds[0][0] == null || bounds[0][0] > c[1])
            bounds[0][0] = c[1];
        if (bounds[0][1] == null || bounds[0][1] > c[0])
            bounds[0][1] = c[0];
        if (bounds[1][0] == null || bounds[1][0] < c[1])
            bounds[1][0] = c[1];
        if (bounds[1][1] == null || bounds[1][1] < c[0])
            bounds[1][1] = c[0];
    });
    return bounds;
};

export default ({ fields, fieldOptions, selectedField, defaultLocation, fieldPopup, onFieldClick, onFieldHover, children, recenter }) => {
    const dispatch = useDispatch();
    const layer = useSelector(selectMapLayer);
    const mapPosition = useSelector(selectMapPosition);
    const mapMoveRef = useRef(null);
    const [bounds, setBounds] = useState(null);
    const [mapKey, setMapKey] = useState(0);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        let resizeTimer;
        const handleResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                // Trigger the callback when the resize has "ended"
                setHeight(window.innerHeight);
            }, 500); // Adjust the debounce time as needed (in milliseconds)
        };

        // Attach the handleResize function to the window resize event
        window.addEventListener("resize", handleResize);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (fields == null) return;
        if (fields.length == 0) {
            setBounds([]);
        } else {
            const farmBounds = [[null, null], [null, null]];
            fields.forEach(field => updateBounds(field, farmBounds));
            setBounds(farmBounds);
        }
    }, [fields]);

    const features = useMemo(() => {
        if (!fields?.length) return [];

        return fields.map(f => ({
            type: "Feature",
            geometry: f.boundaries,
            properties: { fieldId: f.id, options: fieldOptions?.[f.id] || {} }
        }));
        return feat;
    }, [fields, fieldOptions]);

    useEffect(() => {
        if (recenter) handleRecenter();
    }, [recenter]);

    if (bounds == null) return;

    const mapProps = mapPosition
        ? mapPosition
        : bounds?.length
            ? { bounds }
            : { center: defaultLocation || { lat: 47.192294, lng: 8.755809 }, zoom: 13 };

    function handleLayerChange(layer) {
        dispatch(setMapLayer(layer));
    }

    function handleFeature(feature, layer) {
        if (feature?.properties?.fieldId) {
            const handlers = {};
            if (onFieldClick)
                handlers.click = () => onFieldClick(feature?.properties?.fieldId);
            if (onFieldHover) {
                handlers.mouseover = () => onFieldHover(feature?.properties?.fieldId, true);
                handlers.mouseout = () => onFieldHover(feature?.properties?.fieldId, false);
            }
            layer.on(handlers);
        }
    }

    const layerEvents = {
        add: (e) => handleLayerChange(e.target.options.type)
    };

    function handleMapMove(position) {
        if (mapMoveRef.current)
            clearTimeout(mapMoveRef.current);
        mapMoveRef.current = setTimeout(() => {
            // Trigger the callback when the resize has "ended"
            dispatch(setMapPosition(position));
            mapMoveRef.current = null;
        }, 500); // Adjust the debounce time as needed (in milliseconds)
    }

    function handleRecenter(e) {
        if (e) e.preventDefault();
        dispatch(setMapPosition(null));
        setMapKey(mapKey + 1);
    }

    return (<MapContainer
        id="map"
        key={`${mapKey}-${height}`}
        scrollWheelZoom={false}
        style={{ width: "100%", height: `${height - 60}px` }}
        {...mapProps}
    >
        <MyCenter onChange={bounds.length ? handleMapMove : null} />
        <LayersControl position="topright">
            <LayersControl.BaseLayer name="roadmap" checked={!layer || layer === "roadmap"}>
                <ReactLeafletGoogleLayer apiKey={GOOGLE_API_KEY} type="roadmap" eventHandlers={layerEvents} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite" checked={layer === "satellite"}>
                <ReactLeafletGoogleLayer apiKey={GOOGLE_API_KEY} type="satellite" eventHandlers={layerEvents} />
            </LayersControl.BaseLayer>
        </LayersControl>
        <FeatureGroup>
            {features.map(f => <GeoJSON
                key={f.properties.fieldId}
                pathOptions={{ weight: 1.5, color: "blue", fillOpacity: 0, ...f.properties.options }}
                data={f}
                onEachFeature={handleFeature}
            >
                {(fieldPopup || null) && <Popup>{fieldPopup(f.properties.fieldId)}</Popup>}
            </GeoJSON>)}
        </FeatureGroup>
        <CustomLeafletControl position="topleft" classnames="fas fa-arrows-to-eye" onClick={handleRecenter} />
        {children}
    </MapContainer>);
};
