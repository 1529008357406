import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import * as GeometryUtil from "leaflet-geometryutil";
import { EditControl } from "react-leaflet-draw";
import { NOT_EMPTY_VALIDATOR, NAME_VALIDATOR, usePrompt } from "shared";
import { setFilterCrop } from "../../redux/modules/persist";

export default ({ onCreate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prompt = usePrompt();
    const editMode = useRef(false);

    const handleCreated = (e) => {
        const coordinates = e.layer.getLatLngs()[0];
        const area = GeometryUtil.readableArea(GeometryUtil.geodesicArea(coordinates), true);
        prompt(
            t("label-create-field", { area }),
            {
                label: t("label-name"),
                validator: [NOT_EMPTY_VALIDATOR, NAME_VALIDATOR(t("validation-name"))],
                onCancel: () => {
                    e.target.removeLayer(e.layer);
                }
            },
            (name) => {
                const field = {
                    name,
                    boundaries: {
                        type: "Polygon",
                        coordinates: [coordinates.map(c => [c.lng, c.lat])]
                    }
                };
                onCreate(field);
            }
        );
    };

    function handleEdited(e) {
    }

    function handleEditModeStart(e) {
        dispatch(setFilterCrop(null));
        setTimeout(() => editMode.current = true, 500);
    }

    function handleEditModeStop(e) {
        editMode.current = false;
    }

    return (<EditControl
        position="topleft"
        onCreated={handleCreated}
        onEdited={handleEdited}
        onEditStart={handleEditModeStart}
        onDrawStart={handleEditModeStart}
        onEditStop={handleEditModeStop}
        onDrawStop={handleEditModeStop}
        draw={{
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
            polygon: { showArea: true, allowIntersection: false }
        }}
        edit={{ remove: false, edit: Boolean(editMode.current) }}
    />);
};
