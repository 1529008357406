import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Select, FormControl, TextField, IconButton, MenuItem } from "@mui/material";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import { useCropUtils } from "../../hooks/cropUtils";
import { setFilterCrop } from "../../redux/modules/persist";
import { selectFilterCrop } from "../../redux/modules/persist/selectors";
import { selectFields } from "../../redux/modules/farmiq/selectors";

export default () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fields = useSelector(selectFields);
    const filterCrop = useSelector(selectFilterCrop) || "-";
    const cropUtils = useCropUtils();
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

    const distinctCrops = useMemo(() => {
        if (!fields?.length) return [];
        const crops = [];
        fields.forEach(field => {
            const cropId = field.crop?.id;
            if (cropId && !crops.includes(cropId))
                crops.push(cropId);
        });
        return crops;
    }, [fields]);

    const handleCropChange = (e) => {
        const cropId = e.target.value == "-" ? null : e.target.value;
        dispatch(setFilterCrop(cropId));
    };

    if (!distinctCrops?.length) return null;

    const menu = distinctCrops.map((c) => <MenuItem key={c} value={c}>{cropUtils.cropName(c)}</MenuItem>);

    return isSmallScreen
        ? (<FormControl variant="standard" size="small">
            <Select
                value={filterCrop}
                onChange={handleCropChange}
                renderValue={() => <IconButton size="small" edge="end"><SpaOutlinedIcon /></IconButton>}
                disableUnderline
            >
                <MenuItem value="-">&lt;Any crop&gt;</MenuItem>
                {menu}
            </Select>
        </FormControl>)
        : <TextField
            value={filterCrop}
            onChange={handleCropChange}
            select
            InputProps={{ disableUnderline: true, style: { paddingTop: "3px", fontSize: "85%" } }}
            margin="dense"
            variant="standard"
            sx={{ maxWidth: "20ch", marginRight: "1ch", padding: 0, margin: 0 }}
        >
            <MenuItem value="-">&lt;Any crop&gt;</MenuItem>
            {menu}
        </TextField>;
};
