import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField } from "@mui/material";
import { setFilterCrop } from "../../redux/modules/persist";
import { setSelectedFarm } from "../../redux/modules/farmiq";
import { selectFarm, selectFarms } from "../../redux/modules/farmiq/selectors";

export default () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const farms = useSelector(selectFarms);
    const farm = useSelector(selectFarm);

    function handleFarmChange(e) {
        dispatch(setSelectedFarm(e.target.value));
        dispatch(setFilterCrop(null));
    };

    return (<TextField
        value={farm.id}
        helperText={t("label-num-fields", { count: farm.fields.length })}
        onChange={handleFarmChange}
        select
        margin="dense"
        variant="standard"
        sx={{ width: "30ch" }}
    >
        {farms.map((f) => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)}
    </TextField>);
};
