import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import { usePolling } from "../PollingContext";
import { selectFarm, selectFilteredFields } from "../redux/modules/farmiq/selectors";
import { useFarmIQApi } from "../hooks/farmiq";
import ResizableMap from "./widgets/ResizableMap";

const COLOR_MAP = [
    "#E74C3C",
    "#943126",
    "#85929E",
    "#196F3D",
    "#52BE80"
];

const THRESHOLD_MAX = 0.6;
const THRESHOLD_MED = 0.2;


function computeFilteredAssessment(selectedFields, assessmentFields) {
    let totalArea = 0;
    let totalBiom = 0;
    let minBiom = 100;
    let maxBiom = 0;
    const fields = {};
    for (const f of selectedFields) {
        if (f.id in assessmentFields) {
            const { biom, area } = assessmentFields[f.id];
            totalArea += area;
            totalBiom += area * biom;
            if (biom < minBiom)
                minBiom = biom;
            if (biom > maxBiom)
                maxBiom = biom;
            fields[f.id] = { biom };
        } else
            fields[f.id] = { error: true };
    }

    const averageBiom = totalArea ? (totalBiom / totalArea) : 0;

    if (totalArea) {
        // Calculate the maximum absolute difference from the weighted average
        const maxAbsDifference = Math.max(Math.abs(minBiom - averageBiom), Math.abs(maxBiom - averageBiom));
        // Define the thresholds for different labels
        const thresholdMax = maxAbsDifference * THRESHOLD_MAX;
        const thresholdMed = maxAbsDifference * THRESHOLD_MED;

        // Normalize the differences into numerical values
        for (const field of Object.values(fields)) {
            if (field.error) continue;
            const diff = field.biom - averageBiom;
            field.diff = diff;
            field.bucket = Math.abs(diff) < 0.1
                ? 0
                : diff <= -thresholdMax
                    ? -2
                    : diff <= -thresholdMed
                        ? -1
                        : diff >= thresholdMax
                            ? 2
                            : diff >= thresholdMed
                                ? 1
                                : 0;
        }
    }

    fields["-"] = {
        "avg": averageBiom,
        "min": minBiom,
        "max": maxBiom
    };
    return fields;
}

const ColorBox = ({ color, from, to }) => (<div style={{ backgroundColor: color, fontSize: "10px", color: "white", textAlign: "center", width: "60px", height: "30px" }}>
    <div>{Number(from).toFixed(1)}</div>
    <div>{Number(to).toFixed(1)}</div>
</div>);

const ColorPalette = ({ valueRange, currentValue, label, footer }) => {
    const avg = valueRange.avg;
    const maxAbsDifference = Math.max(Math.abs(valueRange.min - avg), Math.abs(valueRange.max - avg));

    return (<Stack alignItems="center" spacing={1}>
        <div>{label}: {currentValue}</div>
        {(valueRange.min < valueRange.max || null) && <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
            <ColorBox color={COLOR_MAP[0]} from={avg - maxAbsDifference} to={avg - maxAbsDifference * THRESHOLD_MAX} />
            <ColorBox color={COLOR_MAP[1]} from={avg - maxAbsDifference * THRESHOLD_MAX} to={avg - maxAbsDifference * THRESHOLD_MED} />
            <ColorBox color={COLOR_MAP[2]} from={avg - maxAbsDifference * THRESHOLD_MED} to={avg + maxAbsDifference * THRESHOLD_MED} />
            <ColorBox color={COLOR_MAP[3]} from={avg + maxAbsDifference * THRESHOLD_MED} to={avg + maxAbsDifference * THRESHOLD_MAX} />
            <ColorBox color={COLOR_MAP[4]} from={avg + maxAbsDifference * THRESHOLD_MAX} to={avg + maxAbsDifference} />
        </Stack>}
        <div>{footer}</div>
    </Stack>);
};

export default () => {
    const { t } = useTranslation();
    const { processing, setProcessing } = usePolling();
    const farm = useSelector(selectFarm);
    const fields = useSelector(selectFilteredFields);
    const farmIQApi = useFarmIQApi();
    const [selectedField, setSelectedField] = useState(null);
    const [assessment, setAssessment] = useState(null);

    useEffect(() => () => setProcessing(null), []);

    useEffect(() => {
        if (farm == null || fields == null) return;

        farmIQApi.getAssessment(farm.id, (assessment) => {
            if (!assessment && !processing) {
                setProcessing(farm.id);
            }
            setAssessment(assessment);
        });
    }, [farm, fields, processing]);

    const fieldOptions = useMemo(() => {
        if (!fields) return null;
        const fieldData = computeFilteredAssessment(fields, assessment?.fields || {});
        const fieldOptions = {
            "-": fieldData?.["-"] || {}
        };
        for (const field of fields) {
            const fId = field.id;
            if (!(fId in fieldData) || fieldData[fId].error) {
                fieldOptions[fId] = { color: "darkgrey", fillOpacity: 0.2, fillColor: "purple", dashArray: "3, 3" };
            } else {
                fieldOptions[fId] = {
                    fillOpacity: 0.6,
                    weight: 3,
                    dashArray: null,
                    color: COLOR_MAP[fieldData[fId].bucket + 2],
                    fillColor: COLOR_MAP[fieldData[fId].bucket + 2],
                };
            }
        }

        return fieldOptions;
    }, [assessment, fields, processing]);

    if (fields == null) return;

    const fieldPopup = (fieldId) => {
        if (!assessment?.fields?.[fieldId]?.biom) return t("message-no-data");

        return (<ColorPalette
            valueRange={fieldOptions["-"]}
            currentValue={assessment.fields[fieldId].biom}
            label={t("title-performance-index")}
            footer={fields.find(f => f.id == fieldId)?.name || fieldId}
        />);
    };

    const farmLocation = farm.location ? { lat: farm.location.coordinates[1], lng: farm.location.coordinates[0] } : null;

    return (<ResizableMap
        fields={fields}
        fieldOptions={fieldOptions}
        fieldPopup={fieldPopup}
        defaultLocation={farmLocation}
        selectedField={selectedField}
    />);
};
