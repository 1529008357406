import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAsyncApi, displayInfoMessage, displayErrorMessage, serializeToJsonapiEntity, deserializeJsonapiEntity, deserializeJsonapiEntities } from "shared";

export const useFieldApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// List fields
    const listFarmFields = async (callback) => {
        const result = await sendRequest("GET", "farms?include=fields&fields[farms]=id,name,location,fields&fields[fields]=id,name,area,center_point,boundaries,crop");
        if (result.status < 300) {
            if (callback) callback(deserializeJsonapiEntities(result.data));
        } else {
            dispatch(displayErrorMessage(t("error-list-fields"), result.data));
        }
        return result;
    };

    ////////////////// Create farm
    const createFarm = async (attributes, callback) => {
        const payload = serializeToJsonapiEntity(null, "farms", attributes);
        const result = await sendRequest("POST", "farms", payload);
        if (result.status < 300) {
            if (callback) callback(deserializeJsonapiEntity(result.data));
        } else {
            dispatch(displayErrorMessage(t("error-create-farm"), result.data));
        }
        return result;
    };

    ////////////////// Create field
    const createField = async (attributes, farmId, callback) => {
        const payload = serializeToJsonapiEntity(null, "fields", attributes);
        payload.data.relationships = { farm: { data: { type: "farms", id: farmId } } };
        const result = await sendRequest("POST", "fields", payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-field-created")));
            if (callback) callback(deserializeJsonapiEntity(result.data));
        } else {
            dispatch(displayErrorMessage(t("error-create-field"), result.data));
        }
        return result;
    };

    ////////////////// Update field
    const patchField = async (fieldId, attributes, callback) => {
        const payload = serializeToJsonapiEntity(fieldId, "fields", attributes);
        const result = await sendRequest("PATCH", `fields/${fieldId}`, payload);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-field-updated")));
            if (callback) callback(deserializeJsonapiEntity(result.data));
        } else {
            dispatch(displayErrorMessage(t("error-update-field"), result.data));
        }
        return result;
    };

    ////////////////// Delete field
    const deleteField = async (fieldId, callback) => {
        const result = await sendRequest("DELETE", `fields/${fieldId}`);
        if (result.status < 300) {
            dispatch(displayInfoMessage(t("message-field-deleted")));
            if (callback) callback();
        } else {
            dispatch(displayErrorMessage(t("error-field-delete"), result.data));
        }
        return result;
    };

    ////////////////// List crops
    const listCrops = async (callback) => {
        const result = await sendRequest("GET", "crops?sort=id");
        if (result.status < 300) {
            if (callback) callback(deserializeJsonapiEntities(result.data));
        } else {
            dispatch(displayErrorMessage(t("error-list-crops"), result.data));
        }
        return result;
    };

    return { listFarmFields, createFarm, createField, patchField, deleteField, listCrops };
};
