import { produce } from "immer";

const initialState = {
    mapLayer: "roadmap",
    filterCrop: null,
};

const reducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_MAP_LAYER":
            draft.mapLayer = action.payload.layer;
            break;
        case "SET_FILTER_CROP":
            draft.filterCrop = action.payload.cropId;
            break;
    }
});

export default reducer;

export const setMapLayer = (layer) => ({ type: "SET_MAP_LAYER", payload: { layer } });

export const setFilterCrop = (cropId) => ({ type: "SET_FILTER_CROP", payload: { cropId } });
