import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button, TextField, InputAdornment } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import { displayErrorMessage, apiUrl, findLocations } from "shared";
import { setFarmFields } from "../redux/modules/farmiq";
import { useFieldApi } from "../hooks/fieldApi";
import ResizableMap from "./widgets/ResizableMap";

export default () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const fieldApi = useFieldApi();
    const [address, setAddress] = useState("");
    const [farmLocation, setFarmLocation] = useState(null);

    function handleAbort() {
        window.location.href = `${apiUrl}/auth/logout?after_logout=${window.location.href}`;
    }

    function handleLocate() {
        findLocations(address, (loc) => {
            if (!loc) {
                setFarmLocation(null);
                dispatch(displayErrorMessage("Location not found"));
            } else {
                setFarmLocation(loc);
            }
        });
    };

    function handleGetStarted() {
        const location = { type: "Point", coordinates: [farmLocation.lng, farmLocation.lat] };
        fieldApi.createFarm({ name: "myfarmiq", location }, () => dispatch(setFarmFields(null)));
    };

    function handleAddressChange(e) {
        setAddress(e.target.value);
    }

    return (<div>
        <ResizableMap fields={[]} defaultLocation={farmLocation} recenter={farmLocation} />
        <Dialog
            open={true}
            maxWidth="xs"
            sx={{ alignItems: "flex-start !important;" }}
            PaperProps={{ sx: { position: "fixed", top: 0, left: 0, mt: 6, ml: 0 } }}
        >
            <DialogTitle>
                {t("title-registration")}
            </DialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography variant="body">{t("text-new-registration")}</Typography>
                <TextField
                    label={t("label-farm-address")}
                    value={address}
                    onChange={handleAddressChange}
                    autoComplete="off"
                    autoFocus
                    variant="standard"
                    fullWidth
                    InputProps={{
                        startAdornment: (<InputAdornment position="start">
                            <LocationOnIcon />
                        </InputAdornment>),
                        endAdornment: (<InputAdornment position="end">
                            <IconButton
                                onClick={handleLocate}
                                edge="end"
                                disabled={address?.length < 5}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={handleAbort}
                    data-cy="button-confirm-cancel">
                    {t("btn-cancel")}
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleGetStarted}
                    disabled={farmLocation == null}
                >
                    {t("btn-get-started")}
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
};
