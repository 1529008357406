import { createSelector } from "reselect";

const selectState = (state) => state.farmiq;
const selectPersistState = (state) => state.persist;

export const selectFields = createSelector(selectState, (state) => state.selectedFarm?.fields || null);

export const selectFilteredFields = createSelector(selectState, selectPersistState, (state, persistState) => {
    if (!state.selectedFarm?.fields) return null;
    if (!persistState.filterCrop) return state.selectedFarm?.fields;
    return state.selectedFarm?.fields.filter(f => f.crop?.id == persistState.filterCrop);
});

export const selectFarms = createSelector(selectState, (state) => state.farms);

export const selectFarm = createSelector(selectState, (state) => state.selectedFarm);

export const selectMapPosition = createSelector(selectState, (state) => state.mapPosition);

export const selectCrops = createSelector(selectState, (state) => state.crops?.list);
export const selectCropsMap = createSelector(selectState, (state) => state.crops?.map);

export const selectMode = createSelector(selectState, (state) => state.mode);
