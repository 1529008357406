import { combineReducers } from "redux";
import { messageReducer } from "shared";
import farmiq from "./farmiq";
import persist from "./persist";

export const reducer = combineReducers({
    messages: messageReducer,
    farmiq,
    persist
});

export const RootState = reducer;
